<template>
    <div>
        <div @click="$router.push('/team')" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="mt-[37px] mb-[30px]">
            <div v-for="student in store.groupStudents" class="flex items-center ml-[30px] mb-[10px]">
                <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#BD9135] border-[2px]">
                    <img v-if="student.photo?.length > 0" :src="apiUrl + 'static/' + student.photo">
                    <div v-else class="w-full h-full bg-[#BD9135] bg-opacity-40 text-[#BD9135] text-[16px] font-semibold flex justify-center items-center">
                        {{ student.name[0] + student.surname[0] }}
                    </div>
                </div>
                <div class="text-white ml-[26px]">{{ student.name + ' ' + student.surname }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '../store/mainStore'
    import { ref } from 'vue'

    export default {
        name: 'AllStudentsInGroup',

        setup() {
            const store = useMainStore()
            const apiUrl = ref(process.env.VUE_APP_API_URL)

            return {
                store,
                apiUrl
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>