<template>
    <div>
        <div @click="$router.push('/'); store.getStudentData()" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div>

            <ImageCropper
                :image="store.imageToCrop"
                @processedImage="processImage"
            />

        </div>
    </div>
</template>

<script>
    import ImageCropper from './ImageCropper.vue'

    import { ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../../store/mainStore'
    import { updateStudentWallpaper } from '../../services/apiRequests'

    export default {
        name: 'WallpaperLoader',
        setup() {
            const store = useMainStore()
            const router = useRouter()

            const processImage = async (file) => {
                await updateStudentWallpaper(store.student.student_id, file)
                await store.getStudentData()
                router.push('/')
            }

            return {
                store,
                processImage
            }

        },

        components: {
            ImageCropper
        }
    }
</script>