<template>
    <div class="calendar-block">
      <div class="mark-container">
        <div>
            <div>
              <div class="types-block">
                <div class="types-label-bar bg-[#0ABFC2] rounded-[4px]"></div>
                <div class="types-label-text text-end text-[#FFF]">
                  тренувався
                </div>
              </div>
              <div class="types-block">
                <div class="types-label-bar bg-[#DA4167] rounded-[4px]"></div>
                <div class="types-label-text text-end text-[#FFF]">
                  пропустив
                </div>
              </div>
              <div class="types-block">
                <div class="types-label-bar border-[1px] border-[#0ABFC2] rounded-[4px]"></div>
                <div class="types-label-text text-end text-[#FFF]">
                  запланова
                </div>
              </div>
              <div class="types-block">
                <div class="types-label-bar bg-[#BD9135] rounded-[4px]"></div>
                <div class="types-label-text text-end text-[#FFF]">
                  змагання
                </div>
              </div>
              <div class="types-block">
                <!-- <div class="types-label-bar bg-[#FFF] rounded-[4px]"></div>
                <div class="types-label-text46ext-end6text-[#FFF]">
                  вихідн9
                </div> -->
              </div>
            </div>
            <div class="visiting-block relative right-[4px]">
                <div class="visiting-element">
                    <div class="count-plate-label text-[#FFF] text-center">
                        тренувань
                    </div>
                    <div class="count-plate-text text-center bg-[#0ABFC2] text-[#FFF] font-semibold rounded-[6px]">
                        {{ trainingsAttendedList.length + trainingsMissedList.length }}
                    </div>
                </div> 
                <div class="visiting-element relative left-[12px]">
                    <div class="count-plate-label text-[#FFF] text-center">
                        відвідувань
                    </div>
                    <div class="count-plate-text text-center bg-[#DA4167] text-white font-semibold rounded-[6px]">
                        {{ trainingsAttendedList.length }}
                    </div>
                </div>

            </div>
        </div>

      </div>

      <div class="">
        <div class="calendar-header-block">
            <span class="calendar-header-text">ВІДВІДУВАННЯ</span>
        </div>
        <div class="flex justify-center items-center mt-[5px]">
            <button @click="previousMonth" class="ml-[60px] mb-[5px] h-[25px]">
              
              <img src="../../assets/left_arrow.svg" class="arrow-height">
      
            </button>
            <span class="current-month-block">{{ currentMonth.toUpperCase() }} {{ currentYear }}</span>
            <button @click="nextMonth" class="mr-[60px] mb-[5px] h-[25px]">
            
              <img src="../../assets/right_arrow.svg" class="arrow-height">
        
            </button>
        </div>
        <table class="mx-auto w-[97%] ">
                <thead height="5px">
                    <tr class="w-[10%]">
                        <th class="text-[#FFF] text-[14px] w-[10px]">Пн</th>
                        <th class="text-[#FFF] text-[14px] w-[10px]">Вт</th>
                        <th class="text-[#FFF] text-[14px] relative left-[1px] w-[10px]">Ср</th>
                        <th class="text-[#FFF] text-[14px] relative left-[1px] w-[10px]">Чт</th>
                        <th class="text-[#FFF] text-[14px] relative left-[3px] w-[10px]">Пт</th>
                        <th class="text-[#FFF] text-[14px] relative left-[3px] w-[10px]">Сб</th>
                        <th class="text-[#FFF] text-[14px] w-[10px]">Нд</th>
                    </tr>
                </thead>
        </table>
        <div class="w-[90%] mx-auto bg-[#47484966] h-px"></div>
        <div class="flex items-center">
            <table class="mx-auto">
                <tbody class="pt-2" >
                <tr v-for="week in weeks" height="12px">
                    <td 
                    v-for="day in week" 
                    :key="day.id" 
                    class="text-center day-plate-container text-[#252526] border-transparent"
                    height="1vw" width="1vw"
                    >
                    <div 
                        class="day-plate text-center align-middle mx-auto "
                        :class="{
                          'bg-transparent' : !day,
                          'text-white pt-[2px] font-extrabold' : day && !isPlaned(day) && !isAttended(day) && !isMissed(day) && day.status != 'mokup',
                          'border-[1px] border-[#0ABFC2] font-extrabold text-white pt-[1px]' : isPlaned(day) && !isAttended(day) && !isMissed(day),
                          'bg-[#DA4167] pt-[2px] text-white font-extrabold' : isMissed(day) && !isAttended(day),
                          'bg-[#0ABFC2] pt-[2px] text-white font-extrabold' : isAttended(day),
                          'opacity-40 font-normal bg-transparent text-[#FFF] pt-[1px]' : day.status === 'mokup'
                        }"
                    >
                        {{ day.monthDay }}
                    </div>
                    </td>
                </tr>
                <tr v-if="weeks.length < 6" height="20px">
                    <td 
                    class="text-center  border-transparent py-[5px]"
                    height="1vw" width="1vw"
                    >
                    <div 
                        class="day-plate font-normal text-center align-middle mx-auto "
                    >
                    </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </template>
  <script>
    import { getWeeksInMonth, addingMatchOfTheDayOfTheWeekAndItsNumberInMonth, addMokups, formatWeeks } from './service/helpers'
    import { getPlanedTrainingsByGroupId } from '../../services/apiRequests'
  
    export default {
      data() {
        return {
          currentMonth: '',
          currentYear: '',
          weeks: [],
  
          mappedDatePlanedTrainings: [],
          mappedDateAttendedTrainings: [],
          mappedDateMissedTrainings: [],
        }
      },
  
      props: {
        groupId: {
          type: String,
          required: true
        },
        trainingsAttendedList: {
          type: Array,
          required: true
        },
        trainingsMissedList: {
          type: Array,
          required: true
        }
      },
  
  
      created() {
        this.currentDate = new Date()
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = addMokups(formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate))))
        console.log('formattedWeeks -------- ', this.weeks)
      },
  
      async mounted() {
        const planedRes = await getPlanedTrainingsByGroupId(this.groupId)

        this.mappedDatePlanedTrainings = planedRes.data.map(item => {
          const dateObj = new Date(item.date)
          dateObj.setDate(dateObj.getDate())
          return dateObj.toISOString().split('T')[0]
        })

        this.mappedDateAttendedTrainings = this.trainingsAttendedList.map(item => {
          const dateObj = new Date(item)
          dateObj.setDate(dateObj.getDate())
          return dateObj.toISOString().split('T')[0]
        })

        this.mappedDateMissedTrainings = this.trainingsMissedList.map(item => {
          const dateObj = new Date(item)
          dateObj.setDate(dateObj.getDate())
          return dateObj.toISOString().split('T')[0]
        })
      },
  
      methods: {
        previousMonth() {
          this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1)
          this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
          this.currentYear = this.currentDate.getFullYear()
          this.weeks = addMokups(formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate))))
          console.log('formattedWeeks -------- ', this.weeks)
        },
        nextMonth() {
          this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1)
          this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
          this.currentYear = this.currentDate.getFullYear()
          this.weeks = addMokups(formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate))))
          console.log('formattedWeeks -------- ', this.weeks)
        },
        isPlaned(day) {
          return this.mappedDatePlanedTrainings.includes(day?.date?.toISOString().split('T')[0])
        },
        isAttended(day) {
          return this.mappedDateAttendedTrainings.includes(day?.date?.toISOString().split('T')[0])
        },
        isMissed(day) {
          return this.mappedDateMissedTrainings.includes(day?.date?.toISOString().split('T')[0])
        }
      }
    }
  </script>
  
<style lang="scss" scoped>
  @media screen and (max-width: 1500px) {
    .calendar-block {
      @apply flex items-center justify-between w-[500px] select-none;
    }

    .types-label-bar {
      @apply w-[46px] h-[8px];
    }

    .types-label-text {
      @apply text-[10px] ml-[9px] w-[90px];
    }

    .day-plate {
      @apply w-[24px] h-[24px] rounded-[2px] text-[14px];
    }

    .day-plate-container {
      @apply px-[5px] text-[14px] border-[10px];
    }

    .arrow-height {
      @apply h-[30px];
    }

    .count-plate-text {
      @apply text-[18px] w-[50px] py-[8px] px-[2px];
    }

    .count-plate-label {
      @apply text-[10px] mb-[2px];
    }

    .calendar-header-text {
      @apply text-[15px] w-full font-semibold text-start text-[#FFF];
    }

    
    .current-month-block {
      @apply text-[14px] w-full font-medium text-center text-[#FFF];
    }
    
    .calendar-header-block {
      @apply flex justify-center relative top-[-1px] items-center ml-[10px];
    }
    
    .mark-container {
      @apply mt-[40px];
    }

    .types-block {
      @apply flex items-center mb-[5px];
    }

    .visiting-block {
      @apply flex justify-between relative top-[38px];
    }
    
    .visiting-element {
      @apply flex flex-col items-center;
    }
  }

  @media screen and (min-width: 1500px) {
    .calendar-block {
      @apply flex items-center justify-between w-[500px] select-none;
    }

    .types-label-bar {
      @apply w-[46px] h-[8px];
    }

    .types-label-text {
      @apply text-[10px] ml-[9px] w-[90px];
    }

    .day-plate {
      @apply w-[24px] h-[24px] rounded-[2px] text-[14px];
    }

    .day-plate-container {
      @apply px-[5px] text-[14px] border-[10px];
    }

    .arrow-height {
      @apply h-[30px];
    }

    .count-plate-text {
      @apply text-[18px] w-[50px] py-[8px] px-[2px];
    }

    .count-plate-label {
      @apply text-[10px] mb-[2px];
    }

    .calendar-header-text {
      @apply text-[15px] w-full font-semibold text-start text-[#FFF];
    }

    
    .current-month-block {
      @apply text-[14px] w-full font-medium text-center text-[#FFF];
    }
    
    .calendar-header-block {
      @apply flex justify-center relative top-[-1px] items-center ml-[10px];
    }
    
    .mark-container {
      @apply mt-[40px];
    }

    .types-block {
      @apply flex items-center mb-[5px];
    }

    .visiting-block {
      @apply flex justify-between relative top-[38px];
    }
    
    .visiting-element {
      @apply flex flex-col items-center;
    }
  }

</style>