<template>
    <div>
        <div @click="$emit('close')" class="flex text-[#BD9135] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#BD9135" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div>
            <div class="border-b-[1px] uppercase border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
                Опишіть причину відсутності
            </div>
    
            <div>
                <div class="w-[90%] mt-5 mx-auto relative flex bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#DA4167] mb-2">
                    <div class="h-[300px] rounded-l-[8px] bg-[#DA4167] w-[7px] mr-[10px]"></div>
                    <div class="text-[12px] align-middle w-[93%] overflow-hidden h-[85%] flex items-center text-[#fff]">
                        <textarea v-model="message" class="w-full h-[300px] bg-transparent outline-none py-[10px]" placeholder="Текст повідомлення..."></textarea>
                    </div>
                </div>
            </div>
    
            <button @click="$emit('sendReason', message)" class="bg-[#DA4167] mb-[40px] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-8 mx-auto">
                <div class="w-full">Повідомити про відсутність</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from 'vue-router'

    export default {
        name: 'MissReasonView',
        setup(emit, { props }) {
            const message = ref('')

            return {
                message
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>