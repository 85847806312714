<template>
  <div>
    <svg ref="svg" :width="width" :height="height">
      <!-- Здесь будет размещен график SVG -->
    </svg>
    <!-- <div v-if="showTooltip" :style="{ top: tooltipTop + 'px', left: tooltipLeft + 'px' }" class="tooltip">{{ tooltipValue }}</div> -->
  </div>
</template>

  
  <script>
  export default {
    name: 'LineChart',
    props: {
      data: {
        type: Array,
        required: true
      },
      width: {
        type: Number,
        required: true
      },
      height: {
        type: Number,
        required: true
      },
      color: {
        type: String,
        default: '#000'
      }
    },
    data() {
      return {
        showTooltip: false,
        tooltipValue: '',
        tooltipTop: 0,
        tooltipLeft: 0
      };
    },
    mounted() {
      this.drawChart();
    },
    methods: {
      drawChart() {
        const labels = ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'];
  
        // Отступ от края холста
        const margin = 30;
  
        // Найти максимальное значение по оси Y
        const maxY = Math.max(...this.data) > 0 ? Math.max(...this.data) : 1;
  
        const svg = this.$refs.svg;
        const plotWidth = this.width - margin * 2;
        const plotHeight = this.height - margin * 2;
        const scaleX = plotWidth / (this.data.length - 1);
        const scaleY = plotHeight / maxY;

        // Создаем элемент <path> для линии графика
        let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        let pathString = `M ${margin} ${this.height - margin - this.data[0] * scaleY}`;
        for (let i = 0; i < this.data.length; i++) {
          const x = i * scaleX + margin;
          const y = this.height - margin - this.data[i] * scaleY;
          pathString += ` L ${x} ${y}`;
        }
        path.setAttribute('d', pathString);
        path.setAttribute('stroke', this.color);
        path.setAttribute('stroke-width', '4');
        path.setAttribute('fill', 'none');
        svg.appendChild(path);

        // Добавляем кружочки на точках графика
        for (let i = 0; i < this.data.length; i++) {
          const x = i * scaleX + margin;
          const y = this.height - margin - this.data[i] * scaleY;

          // Создаем элемент <circle> для кружочка
          let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
          circle.setAttribute('cx', x);
          circle.setAttribute('cy', y);
          circle.setAttribute('r', '3');
          circle.setAttribute('fill', '#252526');
          circle.setAttribute('stroke-width', '1.5');
          circle.setAttribute('stroke', this.color);
          svg.appendChild(circle);
        }

        // Добавляем подписи к точкам графика
        for (let i = 0; i < this.data.length; i++) {
          const x = i * scaleX + margin;
          const y = this.height - margin - this.data[i] * scaleY;

          // Создаем элемент <text> для подписи
          let text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', x - (3.8 * this.data[i].toString().length));
          text.setAttribute('y', y - 8);
          text.setAttribute('font-size', '10px');
          text.setAttribute('font-weight', 'bold');
          text.setAttribute('fill', this.color);
          text.textContent = this.data[i];
          svg.appendChild(text);
        }

        // Добавляем подписи к осям
        for (let i = 0; i < labels.length; i++) {
          const x = i * scaleX + margin;
          const y = this.height - margin + 25;

          // Создаем элемент <text> для подписи осей
          let text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', x - 10);
          text.setAttribute('y', y);
          text.setAttribute('font-size', '9px');
          text.setAttribute('font-weight', 'bold');
          text.setAttribute('fill', this.color);
          text.textContent = labels[i];
          svg.appendChild(text);
        }


      }
    }
}
</script>

<style>
    .tooltip {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        font-weight: bold;
    }
</style>

  