<template>
    <image-viewer 
        v-if="viewImagesFlag"
        :images="filesForView"
        :documentType = "documentsType"
        @close="viewImagesFlag = false"
    />    
    <alerts-settings
        v-if="showAlertsSettings"
        @close="showAlertsSettings = false"
    />
    <training-viewer 
        v-if="showTrainingViewer && trainingForView"
        :trainingObj="trainingForView"
        @close="showTrainingViewer = false"
    />
    <div v-if="loadingSpinnerFlag" class="bg-[#252526] fixed w-full h-full z-[120]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else class="bg-[#252526] pb-6">
        <student-preview />

        <general-information-bar 
            :trainerName="store.trainerName"
            :studentNumber="store.student.player_number"
            :studentAmplua="store.student.amplua"
            :studentAge="store.studentAge"
            :studentGroup="store.group?.name"
            :studentName="store.studentName"
        />
        
        <training-calendar 
            class="mt-[10px]"
            :planedTrainings="store.planedTrainingsData"
            :completedTrainings="store.completedTrainingsByGroup"
            :attendedTrainings="store.studentAttendedTrainings"
            :missedTrainings="store.studentMissedTrainings"
            @planedTraining="showTrainingViewer = true; trainingForView = $event"
        />

        <finance-block 
            v-if="store.group.payment_status == 'paid'"
            :studentIncomes="store.studentOutlays"
        />

        <div class="mt-[30px]">
            <div class="text-white flex justify-between mb-[20px] items-center">
                <div class="text-[9px] tracking-[0.2em]">//////////////</div>
                <div class="text-[15px] font-semibold">МОЯ КОМАНДА</div>
                <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            </div>

            <button @click="$router.push('/team')" class="border-[#BD9135] border-[1px] text-[#BD9135] rounded-[9px] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Переглянути</div>
            </button>

        </div>

        <student-data-block />

        <documents-block 
            @showPassportFiles="viewImagesFlag = true; filesForView = store.student.passport_files; documentsType = 'passport_photo'"
            @showIdCodeFiles="viewImagesFlag = true; filesForView = store.student.id_code_files; documentsType = 'id_code_photo'"
            @showAttorneyFiles="viewImagesFlag = true; filesForView = store.student.attorney_child_files; documentsType = 'attorney_photo'"
            @showPhotosForPassport="viewImagesFlag = true; filesForView = [store.student.photo]; documentsType = 'photos_for_passport'"    
        />

        <button @click="logout" class="bg-[#DA4167] rounded-[9px] text-white px-3 py-3 w-[92%] text-base font-semibold flex items-center align-middle justify-between mt-12 mx-auto">
            <div class="w-full text-sm">Вийти</div>
        </button>
    </div>
</template>

<script>
    import TrainingCalendar from '@/mobileApp/components/TrainingCalendar/TrainingCalendar.vue'
    import StudentPreview from '@/mobileApp/components/StudentPreview/StudentPreview.vue'
    import GeneralInformationBar from '@/mobileApp/components/GeneralInformationBar/GeneralInformationBar.vue'
    import FinanceBlock from '@/mobileApp/components/FinanceBlock/FinanceBlock.vue'
    import StudentDataBlock from '@/mobileApp/components/StudentDataBlock/StudentDataBlock.vue'
    import DocumentsBlock from '@/mobileApp/components/DocumentsBlock/DocumentsBlock.vue'

    import ImageViewer from '@/mobileApp/components/ui/ImageViewer.vue'
    import AlertsSettings from '@/mobileApp/components/AlertsSettings/AlertsSettings.vue'
    import TrainingViewer from '@/mobileApp/components/TrainingViewer/TrainingViewer.vue'

    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, ref } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'

    export default {
        name: 'Main',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useMainStore()

            const viewImagesFlag = ref(false)
            const filesForView = ref([])
            const documentsType = ref('')

            const showAlertsSettings = ref(false)

            const loadingSpinnerFlag = ref(true)

            const showTrainingViewer = ref(false)
            const trainingForView = ref({})

            const genInvoce = () => {
                window.open('https://secure.wayforpay.com/page?vkh=63d3cb16-7eb4-488b-834f-1df322d1dba8', '_blank', 'noreferrer')
            }

            onMounted(async () => {
                try {
                    const studentRes = await store.getStudentData()
                    console.log(studentRes)
                    if (!studentRes) {
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                        router.go()
                        return
                    }
                    await store.getGroupData()
                    await store.getTrainerData()
                    await store.getPlanedTrainings()
                    // await store.getStudentIncomes()
                    await store.getStudentOutlays()
                    await store.getBalanceState()
                    await store.getTelegramBotSubscribed()
                    await store.getStudentCompletedTrainings()
                    await store.getCompletedTrainingsByStudentGroup()
                    loadingSpinnerFlag.value = false
                    
                    setTimeout(() => {
                        if (!localStorage.getItem('showAlertsSettings')) {
                            showAlertsSettings.value = true
                            localStorage.setItem('showAlertsSettings', false)
                        }
                    }, 2000)

                    // if (!store.isBotSubscribed) {
                    // }

                    setInterval(async () => {
                        await store.getBalanceState()
                        await store.getStudentIncomes()
                    }, 5000)
                } catch {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                }
            })

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            return {
                router,
                route,
                store,
                logout,
                viewImagesFlag,
                filesForView,
                genInvoce,
                documentsType,
                showAlertsSettings,
                loadingSpinnerFlag,
                showTrainingViewer,
                trainingForView
            }
        },
        components: {
            StudentPreview,
            TrainingCalendar,
            GeneralInformationBar,
            FinanceBlock,
            StudentDataBlock,
            DocumentsBlock,
            ImageViewer,
            AlertsSettings,
            TrainingViewer
        }
    }
</script>
