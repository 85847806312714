<template>
    <div v-if="isLoading" class="bg-[#252526] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else class="fixed h-full w-full photo-background">
        <!-- <div>
            <img src="../assets/login_bg.svg" class="absolute top-[-173px] w-full scale-[2] h-full">
        </div> -->
        <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/mobileApp/assets/sportkit_logo.svg" alt="">
        </div>
        <!-- absolute top-[56.95%] translate-y-[-50%] left-[50%] translate-x-[-50%] -->
        <div class="flex flex-col align-middle items-center justify-center w-full lg:w-[400px] absolute top-[53%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div class="flex justify-center">
                <img src="@/mobileApp/assets/logo.svg" class="w-[100px]">
            </div>
            <div class="flex justify-center w-10/12 text-[#BD9135] text-[21px] mt-[69px]">
                Hello champion!
            </div>
            <div class="bg-[#474849] bg-opacity-20 rounded-[9px] items-center px-[13px] mt-[45px] border-[1px] border-[#BD9135] flex h-[44px] justify-center w-[356px]">
                <div class="text-[#BD9135] text-[16px] font-semibold relative input-text">+380</div>
                <input v-model="studentLogin" type="text" @focus="removePlaceholderLogin" id="loginInput" class="input-text bg-transparent text-start placeholder:text-center rounded-[9px] outline-none text-[#BD9135] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px] placeholder:relative pr-[50px]" placeholder="номер телефону">
            </div>
            <div class="bg-[#474849] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] mt-[10px] border-[#BD9135] flex h-[44px] justify-center w-[356px]">
                <input v-model="studentPassword" type="password" @focus="removePlaceholderPassword" id="passwordInput" class="input-text bg-transparent placeholder:text-center text-start rounded-[9px] outline-none text-[#BD9135] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px]" placeholder="пароль">
            </div>
            <div class="flex justify-center w-[356px] mt-[30px]">
                <button @click="sendAuthData" class="bg-[#BD9135] border-[1px] border-[#BD9135] rounded-[9px] items-center px-[13px] flex h-[44px] justify-center w-full mb-[16px]">
                    <div class="w-full text-[20px] text-white font-extrabold">Log in</div>

                </button>
            </div>
        </div>
        <div class="absolute bottom-[30px] left-[50%] translate-x-[-50%]">
            <!-- <div class="text-[#BD9135] text-[11px] w-[300px] text-center lg:mx-0 mb-[35px] mx-10">
                Привіт!<br>Це віртуальний кабінет для гравців ФК Колос, якщо у тебе виникли проблеми з доступом, звернись до свого тренера
            </div> -->
            <div class="text-[#fff] text-[11px] text-center w-full">
                © 2023 ALL RIGHTS RESERVED
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { getTokens } from '../services/apiRequests'

    export default {
        name: 'Login',
        setup() {
            const router = useRouter()
            const route = useRoute()

            const studentLogin = ref('')
            const studentPassword = ref('')
            const isLoading = ref(false)

            const sendAuthData = async () => {
                isLoading.value = true
                const res = await getTokens('380' + studentLogin.value, studentPassword.value)
                if (res) {
                    router.go()
                } else {
                    isLoading.value = false
                    alert('Невірний логін або пароль')
                    studentLogin.value = ''
                    studentPassword.value = ''
                }
            }

            const removePlaceholderLogin = () => {
                var input = document.getElementById("loginInput");
                input.removeAttribute("placeholder");
            }

            const removePlaceholderPassword = () => {
                var input = document.getElementById("passwordInput");
                input.removeAttribute("placeholder");
            }

            onMounted(() => {
                console.log('there')
            })

            return {
                router,
                route,
                studentLogin,
                studentPassword,
                sendAuthData,
                removePlaceholderLogin,
                removePlaceholderPassword
            }
        }
    }
</script>

<style lang="scss" scoped>

    @font-face {
        font-family: 'FixelDisplay';
        src: url('../assets/FixelAll/FixelDisplay/FixelDisplay-SemiBold.ttf');
    }

    .input-text {
        font-family: 'FixelDisplay';
    }

    .photo-background {
        background: url(../assets/login_bg.svg) no-repeat center center fixed;
        background-size: cover;
    }

</style>