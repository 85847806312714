<template>
    <MissReasonView v-if="missReasonFlag" @sendReason="e => sendMissReason(e)" @close="missReasonFlag = false" class="absolute w-full h-full z-[999] bg-[#252526]" />
    <transition name="black-bg">
        <div v-if="testFlag" class="bg-black bg-opacity-25 fixed w-full z-10 h-full"></div>
    </transition>
    <transition name="frame">
        <div v-if="testFlag" class="flex flex-col justify-between w-full h-[306px] fixed pt-[10px] bottom-0 z-20 rounded-t-[10px] bg-[#252526]">
            <div>
                <div v-if="multiTraining" class="h-[127px] overflow-y-auto">
                    <div v-for="training in trainingObj" @click="setTraining(training)" class="bg-[#BD9135] rounded-[9px] text-[#252526] px-3 py-1 w-[92%] text-base  text-center mt-2 mx-auto">
                        {{  
                            training.type === 'training' ? 'Тренування' : 
                            training.type === 'gym' ? 'Спортзал' : 
                            training.type === 'competition' ? 'Змагання' : 
                            training.type != 'training' && training.type != 'gym' && training.type != 'competition' ? 'Інше' : ''
                        }}
                    </div>
                </div>
                
                <div v-else>
                    <div class="h-[127px] flex ">
                        <div class="w-[20%] border-r-[1px] flex justify-center items-center border-[#BD913517]">
                            <img v-if="training.type === 'training'" src="../../assets/square_ball_icon.svg">
                            <img v-else-if="training.type === 'competition'" src="../../assets/competition_icon.svg">
                            <img v-else-if="training.type === 'gym'" src="../../assets/gym_icon.svg">
                            <img v-else src="../../assets/square_ball_icon.svg">
                        </div>
                        <div class="w-[60%]">
                            <div class="text-[#fff] mt-2 text-center text-[12px]">
                                {{trainingDate}}
                            </div>
                            <div 
                                class="mt-1 text-center text-[15px]"
                                :class="{
                                    'text-[#0ABFC2]': training.type === 'training',
                                    'text-[#DA4167]': training.type === 'gym',
                                    'text-[#E4F94E]': training.type === 'competition',
                                    'text-[#fff]': training.type != 'training' && training.type != 'gym' && training.type != 'competition'
                                }"    
                            >
                                {{  
                                    training.type === 'training' ? 'Тренування' : 
                                    training.type === 'gym' ? 'Спортзал' : 
                                    training.type === 'competition' ? 'Змагання' : 
                                    training.type != 'training' && training.type != 'gym' && training.type != 'competition' ? 'Інше' : ''
                                }}
                            </div>
                            <div class="flex items-center justify-center">
                                <div class="text-[#fff] mt-2 text-center text-[16px]">
                                    {{ startTime }}
                                </div>
                                <div class="flex text-[#fff] items-center mt-2 mx-2">
                                    >
                                </div>
                                <div class="text-[#fff] mt-2 text-center text-[16px]">
                                    {{ endTime }}
                                </div>
                            </div>
                            <div class="text-[#A1D9F7] mt-2 text-center text-[13px]">
                                {{ locationName }}
                            </div>
                            <div class="text-[#fff] mt-1 text-center text-[13px]">
                                <span class="font-semibold text-[#fff]">Коментар:</span> {{ training.description?.length > 0 ? training.description : 'немає' }}
                            </div>
                        </div>
                        <div  @click="openLocationUrl"  class="w-[20%] border-l-[1px] border-[#BD913517] pt-[5px] flex flex-col justify-center items-center">
                            <div><img src="../../assets/light_map_icon.svg"></div>
                            <div class="text-[#A1D9F7] text-[12px]">маршрут</div>
                        </div>
                    </div>
                    <button v-if="!trainignMissed" @click="missReasonFlag = true" class="bg-[#DA4167] border-[1px] border-[#DA4167] mt-[30px] rounded-[9px] items-center px-[10px] flex h-[34px] justify-center w-[90%] mx-auto mb-[16px]">
                        <div class="w-full text-[14px] text-white font-extrabold">Попередити про відсутність</div>
                    </button>
                    <button v-else @click="missReasonFlag = true" class="border-[1px] border-[#DA4167] text-[#DA4167] mt-[30px] rounded-[9px] items-center px-[10px] flex h-[34px] justify-center w-[90%] mx-auto mb-[16px]">
                        <div class="w-full text-[14px] text-[#DA4167] font-extrabold">Про відсутність попереджено!</div>
                    </button>
                </div>
            </div>
            <div @click="closeView" class="flex justify-center pb-[30px]">
                <img src="../../assets/cross_icon.svg" >
            </div>
        </div>
    </transition>
</template>

<script>
    import { ref, onMounted, onUnmounted } from 'vue'
    import { getLocationById, addMissedTrainingRequest, getMissedTrainingsByStudentId } from '@/mobileApp/services/apiRequests'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import MissReasonView from './MissReasonView.vue'

    export default {
        name: 'training-viewer',

        props: {
            trainingObj: {
                type: [Object, Array],
                required: true
            }
        },

        setup(props, { emit }) {
            const trainingDate = ref('')
            const startTime = ref(props.trainingObj.start_time)
            const endTime = ref(props.trainingObj.end_time)
            const locationName = ref(props.trainingObj.location_label)
            const training = ref(Object.assign({}, props.trainingObj))
            const store = useMainStore()

            const multiTraining = ref(false)
            
            const location = ref({})

            const missReasonFlag = ref(false)

            const trainignMissed = ref(false)

            const openLocationUrl = () => {
                window.open(location.value.geo_url, '_parent')
            }

            const testFlag = ref(false)

            const closeView = () => {
                testFlag.value = false
                setTimeout(() => {
                    emit('close')
                }, 300)
            }

            const setTraining = async (settedTraining) => {
                training.value = settedTraining
                trainingDate.value = settedTraining.date.split('T')[0].split('-').reverse().join('.') 
                startTime.value = settedTraining.start_time
                endTime.value = settedTraining.end_time
                locationName.value = settedTraining.location_label
                const locationRes = await getLocationById(settedTraining.location_id)
                location.value = locationRes.data
                multiTraining.value = false

                const missedTrainings = await getMissedTrainingsByStudentId(store.student.student_id)
                console.log('missedTrainings', missedTrainings)
                missedTrainings.data.forEach(missedTraining => {
                    if (missedTraining.training_id == training.value.training_id) {
                        trainignMissed.value = true
                    }
                })
            }

            const sendMissReason = async (message) => {
                missReasonFlag.value = false

                await addMissedTrainingRequest(
                    store.student.student_id,
                    training.value.training_id,
                    training.value.date,
                    message
                )

                trainignMissed.value = true
            }

            onMounted(async () => {
                setTimeout(() => {
                    testFlag.value = true
                }, 1)

                if (props.trainingObj?.length > 1) {
                    multiTraining.value = true
                    return
                } else if (typeof props.trainingObj === 'object') {
                    training.value = props.trainingObj
                    trainingDate.value = props.trainingObj.date.split('T')[0].split('-').reverse().join('.') 
                    startTime.value = props.trainingObj.start_time
                    endTime.value = props.trainingObj.end_time
                    locationName.value = props.trainingObj.location_label
                    const locationRes = await getLocationById(props.trainingObj.location_id)
                    location.value = locationRes.data

                    const missedTrainings = await getMissedTrainingsByStudentId(store.student.student_id)
                    console.log('missedTrainings', missedTrainings)
                    missedTrainings.data.forEach(missedTraining => {
                        if (missedTraining.training_id == training.value.training_id) {
                            trainignMissed.value = true
                        }
                    })
                }

                trainingDate.value = props.trainingObj.date.split('T')[0].split('-').reverse().join('.') 
                const locationRes = await getLocationById(props.trainingObj.location_id)
                location.value = locationRes.data
            })

            return {
                trainingDate,
                startTime,
                endTime,
                locationName,
                openLocationUrl,
                testFlag,
                closeView,
                multiTraining,
                setTraining,
                training,
                missReasonFlag,
                sendMissReason,
                trainignMissed
            }
        },

        components: {
            MissReasonView
        }
    }
</script>

<style lang="scss">
    @keyframes slide {
        0% {
            bottom: -300px;
        }
        100% {
            bottom: 0px;
        }
    }

    @keyframes showBg {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
        
    .frame-enter-active {
        animation: slide 0.3s ease-in-out forwards;
    }

    .black-bg-enter-active {
        animation: showBg 0.3s ease-in-out forwards;
    }

    .frame-leave-active {
        animation: slide 0.3s ease-in-out reverse forwards;
    }

    .black-bg-leave-active {
        animation: showBg 0.3s ease-in-out reverse forwards;
    }
</style>